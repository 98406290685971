import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home"
import Legacy from "./components/pages/Legacy"
import Footer from './components/Footer'
import Teeshirt from "./components/pages/Teeshirt";
import { Routes, Route } from 'react-router-dom'

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tee-shirt" element={<Teeshirt/>}/>
        <Route path="/legacy" element={<Legacy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
