import React from 'react'

function Legacy() {
    return (
        <div className='w-[90%] md:w-[80%] lg:w-[60%] m-auto my-6'>
            <h1 className='font-bold text-2xl text-center'>MENTIONS LÉGALES DU SITE</h1>
            <h3 className='font-bold text-md text-center my-4'>fetevotive.fr</h3>
            <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21
                juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., il est porté à la
                connaissance des utilisateurs et visiteurs du site Fetevotive.fr les présentes
                mentions légales.</p>
            <br />
            <p className='text-red-500 text-center'>Fetevotive.fr décline toute responsabilité concernant les informations de ce site Internet, rédigées d'après les données de tiers, dont, mais non exclusivement, les informations sur les attractions touristiques et les événements.</p>
            <br />
            <p>
                Le site fetevotive.fr est accessible à l'adresse suivante : <a className='text-sky-700'
                    href="https://www.Fetevotive.fr">https://www.Fetevotive.fr</a> (ci-après "le Site"). L'accès et
                l'utilisation du Site sont soumis aux présentes
                " Mentions légales" détaillées ci-après ainsi qu'aux lois et/ou règlements applicables.
            </p>
            <br />
            <p>La connexion, l'utilisation et l'accès à ce Site impliquent l'acceptation intégrale et sans
                réserve de l'internaute de toutes les dispositions des présentes Mentions Légales</p>
            <h2 className='mt-4 mb-2 font-bold text-xl'>ARTICLE 1 – INFORMATIONS LÉGALES</h2>
            <p>En vertu de l'Article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans
                l'économie numérique, il est précisé dans cet article l'identité des différents
                intervenants dans le cadre de sa réalisation et de son suivi.</p>
            <br /><br /><br />
            <p className='font-bold underline'><span>A.</span> Editeur du site</p>
            <br />
            <p className='font-bold underline'><span>Marco Vassal</span></p>
            <br />
            <p>domicilié à l'adresse suivante :282 Route de Chastanet, 07110 VALGORGE, et
                immatriculé au numéro suivant : !!!.</p>
            <br />
            <p>Téléphone : 06 44 87 04 71</p>
            <br />
            <p>Adresse e-mail : <a className='text-sky-700 ' href="mailto:marco.vassal07@gmail.comr">marco.vassal07@gmail.com</a></p>
            <br />
            <p>ci-après "l'Editeur"</p>
            <br />
            <p className='font-bold underline'><span>B. </span>Directeur de publication </p>
            <br />
            <p>Le Directeur de publication est :</p>
            <br />
            <p className='font-bold underline'><span>Marco Vassal</span></p>
            <br />
            <p>Adresse e-mail de contact : <a className='text-sky-700 ' href="mailto:marco.vassal07@gmail.com"></a>marco.vassal07@gmail.com</p>
            <br />
            <p>ci-après "le Directeur de publication"</p>
            <br /><br />
            <p className='font-bold underline'><span>C.</span>Hébergeur du site</p>
            <br />
            <p>Le site Fetevotive.fr est hébergé par :</p>
            <br />
            <p className='font-bold underline'><span>Hostinger</span></p>
            <br />
            <p>dont le siège est situé à l'adresse suivante : HOSTINGER INTERNATIONAL LTD, 61
                Lordou Vironos Street, 6023 Larnaca, Chypre, joignable par le moyen suivant : <a className='text-sky-700 '
                    href="https://www.hostinger.fr/contact">https://www.hostinger.fr/contact</a></p>
            <br />
            <p>ci-après "l'Hébergeur"</p>
            <br /><br />
            <p className='font-bold underline'><span>D.</span>Utilisateurs</p>
            <br />
            <p>Sont considérés comme utilisateurs tous les internautes qui naviguent, lisent,
                visionnent et utilisent le site Fetevotive.fr.</p>
            <br />
            <p>ci-après les "Utilisateurs"</p>
            <h2 className='mt-4 mb-2 font-bold text-xl'>ARTICLE 2 – CONFIDENTIALITE</h2>
            <p className='font-bold underline'><span>A.</span> DONNEES COLLECTEES ET TRAITEES</p>
            <br />
            <p>Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la
                collecte et le traitement des données des utilisateurs du site respectent les principes
                suivants :</p>
            <ul>
                <li>Licéité, loyauté et transparence : les données ne peuvent être collectées et
                    traitées qu'avec le consentement de l'utilisateur propriétaire des données. A
                    chaque fois que des données à caractère personnel seront collectées, il sera
                    indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons
                    ses données sont collectées</li>
                <li>Finalités limitées : la collecte et le traitement des données sont exécutés pour
                    répondre à un ou plusieurs objectifs déterminés dans les présentes mentions
                    légales</li>
                <li>Minimisation de la collecte et du traitement des données : seules les données
                    nécessaires à la bonne exécution des objectifs poursuivis par le site sont
                    collectées
                </li>
                <li>Conservation des données réduites dans le temps : les données sont
                    conservées pour une durée limitée, dont l'utilisateur est informé. Lorsque cette
                    information ne peut pas être communiquée, l'utilisateur est informé des critères
                    utilisés pour déterminer la durée de conservation</li>
                <li>Intégrité et confidentialité des données collectées et traitées : le responsable
                    du traitement des données s'engage à garantir l'intégrité et la confidentialité
                    des données collectées.</li>
            </ul>
            <p>Afin d'être licite, et ce conformément aux exigences de l'article 6 du règlement
                européen 2016/679, la collecte et le traitement des données à caractère personnel ne
                pourront intervenir que s'ils respectent au moins l'une des conditions ci-après
                énumérées :</p>
            <ul className='list-disc md:p-8'>
                <li>L'utilisateur a expressément consenti au traitement</li>
                <li>Le traitement est nécessaire à la bonne exécution d'un contrat</li>
                <li>Le traitement répond à une obligation légale</li>
                <li>Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts
                    vitaux de la personne concernée ou d'une autre personne physique</li>
                <li>Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission
                    d'intérêt public ou qui relève de l'exercice de l'autorité publique
                </li>
                <li>Le traitement et la collecte des données à caractère personnel sont
                    nécessaires aux fins des intérêts légitimes et privés poursuivis par le
                    responsable du traitement ou par un tiers</li>
            </ul>
            <p className='font-bold underline'><span>B.</span>HEBERGEMENT DES DONNEES</p>
            <br />
            <p>Tel que mentionné plus haut, le site Fetevotive.fr est hébergé par : Hostinger, dont
                le siège est situé à l'adresse ci-après :
            </p>
            <br />
            <p>61 Lordou Vironos Street, 6023 Larnaca, Chypre</p>
            <br />
            <p>Les données collectées et traitées par le site sont exclusivement hébergées et traitées
                en France.</p>
            <br /><br />
            <p className='font-bold underline'><span>C.</span>LE RESPONSABLE DU TRAITEMENT DES DONNÉES
            </p>
            <br />
            <p>Le responsable du traitement des données à caractère personnel est : Marco Vassal.
                Il peut être contacté de la manière suivante :
            </p>
            <br />
            <p>Par e-mail : <a className='text-sky-700 ' href="mailto:marco.vassal07@gmail.com"></a>marco.vassal07@gmail.com</p>
            <br /><br />
            <p className='font-bold underline'><span>D.</span>DONNEES PERSONNELLES DES PERSONNES MINEURES
            </p>
            <br />
            <p>Conformément aux dispositions de l'article 8 du règlement européen 2016/679 et à la
                loi Informatique et Libertés, seuls les mineurs âgés de 15 ans ou plus peuvent
                consentir au traitement de leurs données personnelles.
            </p>
            <br />
            <p>Si l'utilisateur est un mineur de moins de 15 ans, l'accord d'un représentant légal sera
                requis afin que des données à caractère personnel puissent être collectées et traitées.</p>
            <br /><br />
            <p className='font-bold underline'><span>E.</span>DROITS DE L'UTILISATEUR ET PROCÉDURES DE MISE EN OEUVRE DES
                DROITS DE L'UTILISATEUR
            </p>
            <br />
            <p>Conformément à la réglementation concernant le traitement des données à caractère
                personnel, l'Utilisateur possède les droits ci-après énumérés.</p>
            <br />
            <p>Afin que le responsable du traitement des données fasse droit à sa demande,
                l'utilisateur est tenu de lui communiquer : ses prénom et nom ainsi que son adresse
                e-mail, et si cela est pertinent, son numéro de compte ou d'espace personnel ou
                d'abonné.
            </p>
            <br />
            <p>Le responsable du traitement des données est tenu de répondre à l'Utilisateur dans
                un délai de 30 (trente) jours maximum.</p>
            <br /><br />
            <p>a. Droit d'accès, de rectification et droit à l'oubli</p>
            <br />
            <p>L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la
                suppression des données le concernant, en respectant la procédure ci-après énoncée:
            </p>
            <br />
            <p>Envoie d’un mail à <a className='text-sky-700 ' href="mailto:marco.vassal07@gmail.com">marco.vassal07@gmail.com</a> avec son nom, son
                adresse et la
                demande explicité de manière précise.
            </p>
            <br /><br />
            <p>b. Droit à la portabilité des données</p>
            <br />
            <p>L'Utilisateur a le droit de demander la portabilité de ses données personnelles,
                détenues par le site, vers un autre site, en se conformant à la procédure ci-après :</p>
            <br />
            <p>Envoie d’un mail à <a className='text-sky-700 ' href="marco.vassal07@gmail.com">marco.vassal07@gmail.com</a> avec son nom, son
                adresse et la
                demande explicité de manière précise.</p>
            <br /><br />
            <p>c. Droit à la limitation et à l'opposition du traitement des données</p>
            <br />
            <p>Enfin, l'Utilisateur a le droit de demander la limitation ou de s'opposer au traitement
                de ses données par le site, sans que le site ne puisse refuser, sauf à démontrer
                l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les
                droits et libertés de l'Utilisateur.
            </p>
            <br /><br />
            <p>d. Droit de déterminer le sort des données après la mort</p>
            <br />
            <p>Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données
                collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre
                2016.</p>
            <br /><br />
            <p>e. Droit de saisir l'autorité de contrôle compétente</p>
            <p>Dans le cas où le responsable du traitement des données décide de ne pas répondre
                à la demande de l'Utilisateur, et que l'Utilisateur souhaite contester cette décision, il
                est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés,<a className='text-sky-700 ' target="_blank"
                    href="https://www.cnil.fr">https://www.cnil.fr</a>) ou tout juge compétent.</p>
            <br /><br />
            <p className='font-bold underline'><span>F.</span>OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</p>
            <br />
            <p>Le responsable du traitement s'engage à protéger les données à caractère personnel
                collectées, à ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été
                informé et à respecter les finalités pour lesquelles ces données ont été collectées.</p>
            <br />
            <p>Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert
                des données transitant par le site sont sécurisés.</p>
            <br />
            <p>De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en
                cas de rectification ou de suppression des données, à moins que cela n'entraîne pour
                lui des formalités, coûts et démarches disproportionnés.
            </p>
            <br />
            <p>Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère
                personnel de l'Utilisateur est compromise, le responsable du traitement s'engage à
                informer l'Utilisateur par tout moyen.</p>
            <br /><br />
            <p className='font-bold underline'><span>G.</span>COOKIES</p>
            <br />
            <p>Le site a éventuellement recours aux techniques de "cookies" lui permettant de traiter
                des statistiques et des informations sur le trafic, de faciliter la navigation et d'améliorer
                le service pour le confort de l'utilisateur. Pour l'utilisation de fichiers "cookies"
                impliquant la sauvegarde et l'analyse de données à caractère personnel, le
                consentement de l'utilisateur est nécessairement demandé.</p>
            <br />
            <p>Ce consentement de l'Utilisateur est considéré comme valide pour une durée de 13
                (treize) mois maximum. A l'issue de cette période, le site demandera à nouveau
                l'autorisation de l'utilisateur pour enregistrer des fichiers "cookies" sur son disque dur.</p>
            <br /><br />
            <p>a. Opposition de l'utilisateur à l'utilisation de fichiers "cookies" par le site</p>
            <br />
            <p>Il est porté à la connaissance de l'Utilisateur qu'il peut s'opposer à l'enregistrement de
                ces "cookies" en configurant son logiciel de navigation.
            </p>
            <br />
            <p>Dans le cas où l'utilisateur décide de désactiver les fichiers "cookies", il pourra
                poursuivre sa navigation sur le site. Toutefois, tout dysfonctionnement du site
                provoqué par cette manipulation ne pourrait être considéré comme étant du fait de
                l'Editeur du site.</p>
            <br /><br />
            <p>b. Description des fichiers "cookies" utilisés par le site
            </p>
            <br />
            <p>L'Editeur du site attire l'attention de l'Utilisateur sur le fait que les cookies suivants sont
                utilisés lors de sa navigation :</p>
            <br />
            <p>Hostinger : Optimisation SEO</p>
            <br />
            <p>De plus, le site intègre des boutons de réseaux sociaux, permettant à l'Utilisateur de
                partager son activité sur le site. Des fichiers "cookies" de ces réseaux sociaux sont
                par conséquent susceptibles d'être stockés sur l'ordinateur de l'utilisateur lorsqu'il
                utilise ces fonctionnalités.</p>
            <br />
            <p>L'attention de l'utilisateur est portée sur le fait que ces sites disposent de politiques de
                confidentialité propres, possiblement différentes de celles présentées ici. L'Editeur du
                site invite les utilisateurs à consulter les politiques de confidentialité et de gestions des
                données personnelles de ces sites.</p>
            <h2 className='mt-4 mb-2 font-bold text-xl'>ARTICLE 3 – ACCESSIBILITÉ
            </h2>
            <p>Le Site est par principe accessible aux Utilisateurs 24/24h et 7/7j, sauf interruption,
                programmée ou non, pour des besoins de maintenance ou en cas de force majeure.</p>
            <br />
            <p>En cas d'impossibilité d'accès au Site, celui-ci s'engage à faire son maximum afin d'en
                rétablir l'accès. Le Site ne saurait être tenu pour responsable de tout dommage, quelle
                qu'en soit la nature, résultant de son indisponibilité.</p>
            <h2 className='mt-4 mb-2 font-bold text-xl'>ARTICLE 4 – LOI APPLICABLE ET JURIDICTION</h2>
            <p>Les présentes Mentions Légales sont régies par la loi française. En cas de différend
                et à défaut d'accord amiable, le litige sera porté devant les tribunaux français
                conformément aux règles de compétence en vigueur.</p>
            <h2 className='mt-4 mb-2 font-bold text-xl'>ARTICLE 5 – CONTACT
            </h2>
            <p>Pour tout signalement de contenus ou d'activités illicites, l'Utilisateur peut contacter
                l'Éditeur à l'adresse suivante : <a className='text-sky-700 ' href="marco.vassal07@gmail.com">marco.vassal07@gmail.com</a>, ou
                par courrier recommandé
                avec accusé de réception adressé à l'Éditeur aux coordonnées précisées dans les
                présentes mentions légales</p>
            <br />
            <br />
            <br />
            <p className='text-center'>
                Le site Fetevotive.fr vous souhaite une excellente navigation !
            </p>
        </div>
    )
}

export default Legacy