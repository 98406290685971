import Hero from "../Hero";
import Card from "../Card";
import Reminder from "../Reminder"
import Footer from "../Footer";
import React from 'react'
import Verif from "../Verif";
import Partenaire from "../Partenaire";

function Home() {
    return (
        <>
            <Hero />
            <Partenaire />
            {/* <Reminder/> */}
            <Card />
            <Verif/>
        </>
    )
}

export default Home