import React from 'react'
import HeroTeeshirt from '../HeroTeeshirt'
import InfosTeeshirt from '../InfosTeeshirt'

function Teeshirt() {
    return (
        <>
            <HeroTeeshirt />
            <InfosTeeshirt/>
        </>
    )
}
export default Teeshirt