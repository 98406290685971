import React from 'react'
import Typed from 'react-typed'
import { data } from '../data/data.jsx'
const { useState, useEffect } = React;

const Hero = () => {
    const currentDate = new Date()
    //Filter by date
    data.sort((a, b) => new Date(a.date) - new Date(b.date));
    const mydata = data.filter(item => new Date() <= new Date(item.endDate));

    const [countdownDate, setCountdownDate] = useState(
        mydata && mydata.length > 0
            ? new Date(mydata[0].date) > new Date()
                ? new Date(mydata[0].date).getTime()
                : new Date(mydata[0].endDate).getTime()
            : undefined
    );

    const [state, setState] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        setInterval(() => setNewTime(), 1000);
    }, []);

    const setNewTime = () => {
        if (countdownDate) {
            const currentTime = new Date().getTime();

            const distanceToDate = countdownDate - currentTime;

            let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
            let hours = Math.floor(
                (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
            );
            let minutes = Math.floor(
                (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
            );
            let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

            const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

            days = `${days}`;
            if (numbersToAddZeroTo.includes(hours)) {
                hours = `0${hours}`;
            } else if (numbersToAddZeroTo.includes(minutes)) {
                minutes = `0${minutes}`;
            } else if (numbersToAddZeroTo.includes(seconds)) {
                seconds = `0${seconds}`;
            }

            setState({ days: days, hours: hours, minutes, seconds });
        }
    };
    return (
        <div className='sm:bg-hero-background bg-hero-phone-background w-full max-h-[1000px] min-h-[90vh] bg-cover bg-center '>
            <div className="px-[4%] text-center w-full max-h-[1000px] min-h-[90vh] bg-black bg-opacity-50 text-white flex justify-between flex-col items-center">
                <div className='pt-[5vh] sm:pt-[8vh]'>
                    <a href="/tee-shirt" className="button-tee flex w-fit justify-center mx-auto whitespace-nowrap my-2 hover:bg-white text-center min-[400px]:text-base bg-blue-600 border border-white text-white hover:text-blue-600 hover:border-blue-600 font-bold py-2 px-4 md:px-8 md:py-4 md:text-xl rounded duration-300">Acheter un Tee-shirt</a>

                    <p className='text-xl mt-[5vh] sm:mt-[8vh] font-extralight sm:text-2xl lg:text-4xl'>{mydata[0] ? mydata[0].dateShow : ''}</p>
                    <h1 className='uppercase text-2xl sm:text-3xl font-extrabold py-10 lg:text-5xl'>{mydata[0] ? mydata[0].title : ""}</h1>
                    {mydata[0] && new Date(mydata[0].date) > currentDate ? (
                        <h1 className='text-xl sm:text-xl font-bold lg:text-xl pb-4'>La fête commence dans :</h1>
                    ) : (
                        <>
                            <h1 className='text-xl sm:text-xl font-bold lg:text-xl'>La fête a déjà commencé, ils vous attendent nombreux !</h1>
                            <h1 className='text-xl sm:text-xl font-bold lg:text-xl pb-4'>Elle finit dans :</h1>
                        </>
                    )}< div className='w-full flex font-bold justify-around items-center lg:w-[80%] lg:m-auto'>
                        <div className='w-20 border rounded flex flex-col lg:w-24'>
                            <div className='text-4xl font-bold border-b py-2 lg:text-5xl'>{state.days || '0'}</div>
                            <small className="w-full text-xs uppercase py-1 lg:text-sm">Jours</small>
                        </div>
                        <div className='w-20 border rounded flex flex-col lg:w-24'>
                            <div className='text-4xl font-bold border-b py-2 lg:text-5xl'>{state.hours || '00'}</div>
                            <small className="w-full text-xs uppercase py-1 lg:text-sm">Heures</small>
                        </div>
                        <div className='w-20 border rounded flex flex-col lg:w-24'>
                            <div className='text-4xl font-bold border-b py-2 lg:text-5xl'>{state.minutes || '00'}</div>
                            <small className="w-full text-xs uppercase py-1 lg:text-sm">Minutes</small>
                        </div>
                        <div className='w-20 border rounded flex flex-col lg:w-24'>
                            <div className='text-4xl font-bold border-b py-2 lg:text-5xl'>{state.seconds || '00'}</div>
                            <small className="w-full text-xs uppercase py-1 lg:text-sm">Secondes</small>
                        </div>
                    </div>

                    <h1 className='uppercase text-xl font-bold w-fit mx-auto mt-24 bg-black rounded-lg p-1 sm:text-2xl'>
                        Prêt à&nbsp;
                        <Typed className='text-green-400'
                            strings={[
                                'faire la fête ?',
                                'jouer à la pétanque ?',
                                "s'amuser ?"
                            ]}
                            typeSpeed={90}
                            backSpeed={70}
                            loop
                        />
                    </h1>
                </div>
                <h2 onClick={() => window.scrollTo({ top: window.pageYOffset + (window.innerHeight * 0.901), behavior: 'smooth' })} className='flex justify-center animate-bounce-slow items-center text-xl font-bold pb-6 mb-14 sm:mb-0 sm:pb-8 sm:text-2xl hover:cursor-pointer'><img className='px-4' src="/images/Angle-double-down.svg" alt="" /> Alors c'est par là<img className='px-4' src="/images/Angle-double-down.svg" alt="" /></h2>
            </div>

        </div >
    )
}

export default Hero