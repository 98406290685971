import React from 'react'
import { data } from '../data/data.jsx'
import { useState } from 'react';

const Card = () => {

  const today = new Date();

  // Tri des données par date
  data.sort((a, b) => new Date(a.date) - new Date(b.date));

  // Filtrage des données en fonction de la date actuelle
  const mydata = data.filter(item => today <= new Date(item.endDate));
  
  //Filter Votive/pétanque/etc
  const filterType = (category) => {
    setVotives(
      data.filter((item) => {
        if (item.category.indexOf(category) !== -1 && new Date(item.endDate) > today) {
          return item.category
        }
      })
    )
  }

  const [filteredVotives, setVotives] = useState(mydata)
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };


  return (
    <div className='w-full  lg:max-w-[1250px] m-auto pb-6 px-4' >
      <div className='flex justify-center m-6'>
        <a class="custom-btn add-votive" href="mailto:marco.vassal07@gmail.com">Ajouter une votive</a>
        </div>
      <div className='sm:w-[80%] sm:m-auto'>
        <p className='font-bold'>Filtres :</p>
        <div className='font-bold text-xl flex-wrap text-gray-700 sm:text-center'>
          <button onClick={() => setVotives(mydata)} className='uppercase px-2 hover:text-green-400 duration-200 focus:underline focus:text-green-400 border rounded m-2'>Tout</button>
          <button onClick={() => filterType('votive')} className='uppercase px-2 hover:text-green-400 duration-200 focus:underline focus:text-green-400 border rounded m-2'>Votive</button>
          <button onClick={() => filterType('petanque')} className='uppercase px-2 hover:text-green-400 duration-200 focus:underline focus:text-green-400 border rounded m-2'>Pétanque</button>
          <button onClick={() => filterType('foraine')} className='uppercase px-2 hover:text-green-400 duration-200 focus:underline focus:text-green-400 border rounded m-2'>Fête Foraine</button>
          <button onClick={() => filterType('juillet')} className='uppercase px-2 hover:text-green-400 duration-200 focus:underline focus:text-green-400 border rounded m-2'>Juillet</button>
          <button onClick={() => filterType('aout')} className='uppercase px-2 hover:text-green-400 duration-200 focus:underline focus:text-green-400 border rounded m-2'>Août</button>
          {/* <button onClick={() => filterType('septembre')} className='uppercase px-2 hover:text-green-400 duration-200 focus:underline focus:text-green-400 border rounded m-2'>Septembre</button> */}
        </div>
      </div>


      <div className={`grid ${filteredVotives.length === 0 ? 'grid-cols-1 justify-items-center' : 'grid-cols-1 md:grid-cols-2'}`}>
        {filteredVotives.length === 0 ? (
          <div className='text-center bg-black bg-opacity-30 p-4 rounded-md mt-4 max-w-[400px]'>
            {/* Contenu à afficher si votives est vide */}
            <p className='font-bold'>Aucune votive prévu pour le filtre choisi.</p>
            <p className='text-xs text-red-600 mt-2'>essaye de changer de filtre</p>
          </div>
        ) : (
          <React.Fragment>
            {filteredVotives.map((item, index) => (
              <div key={index} className='py-3 m-2 px-2 shadow-2xl rounded-xl flex flex-col items-center hover:cursor-pointer'>
                <div className='flex w-full flex-col items-center md:items-start lg:flex-row' onClick={() => handleClick(item.imageUrl)}>
                  <img className='w-[15em] object-contain border-black border-4 lg:hover:w-[17em] duration-300' src={item.imageUrl === '' ? "/images/affiches/soon.webp" : item.imageUrl} alt={item.alt} />
                  <div className='lg:pl-4 sm:w-[80%] md:w-[100%] flex flex-col justify-between h-full'>
                    <div>
                      <h1 className='font-bold text-lg mt-4 underline-offset'>{item.title}</h1>
                      <p className='font-bold text-gray-600 my-2'>{item.dateShow}</p>
                      <p className='text-green-600 font-bold mb-2'>{item.price}</p>
                      <p className='text-gray-600 text-sm font-sans mb-2'>{item.description}</p>
                    </div>
                    {item.compteInsta !== '' && (
                      <a href={item.compteInsta} target="_blank" className='w-[100%] px-2 items-center justify-around py-2 border rounded hidden lg:flex blank'>
                        <p className='font-bold'>Instagram de l'organisation :</p>
                        <img src='/images/partenaires/instagram.webp' alt='Compte instagram du comité des fêtes/jeunes' className='w-10' />
                      </a>
                    )}
                  </div>
                </div>
                {item.compteInsta !== '' && (
                  <a href={item.compteInsta} className='w-[326px] flex items-center justify-around py-2 border rounded lg:hidden'>
                    <p className='font-bold'>Instagram de l'organisation :</p>
                    <img src='/images/partenaires/instagram.webp' alt='' className='w-10' />
                  </a>
                )}
              </div>
            ))}
            {selectedImage && (
              <div className='fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 duration-500 z-20'>
                <div className='max-w-xl mx-auto'>
                  <img className='max-w-full' src={selectedImage} alt='Affiche de la fête votive' onClick={handleClose} />
                  <button className='absolute top-2 right-2 text-white font-bold bg-red-500 px-4 py-2 rounded' onClick={handleClose}>
                    X
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default Card